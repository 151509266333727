<template>
  <div class="income">
    <div class="top">
      <div class="imgs"></div>
      <div class="conte">
        <div class="title">几人作开篇人NFT（V er.1）</div>
        <div class="item">
          <span class="label">合约地址：</span>
          <span class="value">{{ obj.contractAddress }}</span>
        </div>
        <div class="item">
          <span class="label">ID：</span>
          <span class="value">{{ obj.nftId }}</span>
        </div>
        <div class="item">
          <span class="label">兑换时间：</span>
          <span class="value">{{ obj.redeemTime }}</span>
        </div>
      </div>
      <el-image class="elImgs" :src="obj.url" fit="cover"></el-image>
      <div class="conte">
        <div class="title">绑定作品:{{ obj.contractAddress }}</div>
        <div class="item">
          <span class="label">作者：</span>
          <span class="value">{{ obj.contractAddress }}</span>
        </div>
        <div class="item">
          <span class="label">发布时间：</span>
          <span class="value">{{ obj.createTime }}</span>
        </div>
      </div>
    </div>
    <div class="banner">
      <div class="left">
        <span>收益合计：</span>
        <div class="nums">
          <div class="item">
            <span class="money">{{ moneyObj.totalETH }}</span>
            ETH
            <img
              style="width: 20px; margin-left: 8px"
              src="@/assets/images/ethBig.png"
              alt=""
            />
          </div>
          <div class="item">
            <span class="money">{{ moneyObj.totalJRZ }}</span>
            DAIC
            <img
              style="width: 20px; margin-left: 8px"
              src="@/components/Header/images/btc.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/xuxie.png"
              alt=""
            />
            <span>续写收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.totalETH }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.totalJRZ }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/liwu.png"
              alt=""
            />
            <span>礼品收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.totalETH }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.totalJRZ }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/yuedu.png"
              alt=""
            />
            <span>阅读收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.totalETH }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.totalJRZ }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="shouyi">
          <div class="imgs">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/qita.png"
              alt=""
            />
            <span>其他收益</span>
          </div>
          <div class="nums">
            <div class="item">
              <span class="money">{{ moneyObj.totalETH }}</span>
              ETH
              <img
                style="width: 20px; margin-left: 8px"
                src="@/assets/images/ethBig.png"
                alt=""
              />
            </div>
            <div class="item">
              <span class="money">{{ moneyObj.totalJRZ }}</span>
              DAIC
              <img
                style="width: 20px; margin-left: 8px"
                src="@/components/Header/images/btc.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hader">NFT关联收入记录</div>
    <div
      class="content"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      element-loading-text="拼命加载中"
    >
      <div class="table">
        <div class="table-column" v-for="item in list" :key="item.id">
          <div class="column">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/liwu.png"
              alt=""
            />
            <span>礼品</span>
          </div>
          <div class="column">
            <span>来自: </span>
            <el-avatar shape="square" size="medium" :src="item.avatar" />
            <span class="nickName">{{ item.user }}</span>
          </div>

          <div class="column">
            <span>{{ item.money }}</span>
            <span>{{ item.moneytype }}</span>
            <img
              v-if="item.moneytype == 'ETH'"
              style="width: 20px; margin-left: 8px"
              src="@/assets/images/ethBig.png"
              alt=""
            />
            <img
              v-else
              style="width: 20px; margin-left: 8px"
              src="@/components/Header/images/btc.png"
              alt=""
            />
          </div>
          <div class="column">
            <img
              style="width: 20px; margin-bottom: 4px"
              src="./img/time.png"
              alt=""
            />
            <span>{{ item.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "income",
  data() {
    return {
      list: [
        {
          id: 1,
          type: 1,
          user: "你莫言",
          money: "25.8876",
          moneytype: "ETH",
          time: "2024.05.16 15:32:56",
          avatar: "",
        },
      ],
      obj: {
        contractAddress: "",
        nftId: "",
        redeemTime: "",
      },
      queryParams: {
        page: 1,
        pageSize: 10,
        totalRows: 0,
        type: 0,
      },
      moneyObj: {
        totalETH: 135.8869,
        totalJRZ: 145265.5888,
      },
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      // this.loading = true;
      // personalPage(this.queryParams).then(({ success, data }) => {
      //   if (success) {
      //     this.list = data.items;
      //   }
      //   this.loading = false;
      // });
    },
  },
};
</script>

<style scoped lang="less">
.income {
  width: 1200px;
  margin: 20px auto;
  min-height: 80vh;
  .top {
    display: flex;
    .imgs {
      box-sizing: border-box;
      width: 80px;
      height: 122px;
      border: 2px solid #999;
      margin-right: 12px;
      background: url(../../assets/images/creatImg.png) no-repeat;
      background-size: 100% 100%; /* 背景图充满元素 */
      .dataImg {
        margin: 160px auto;
        height: 80px;
        width: 140px;
        border: solid 2px #ccc;
        border-radius: 8px;
      }
    }
    .elImgs {
      box-sizing: border-box;
      width: 183px;
      height: 122px;
      border: 2px solid #999;
      margin-right: 12px;
    }
    .conte {
      .title {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 16px;
      }
      .item {
        width: 100%;
        line-height: 24px;
        font-size: 12px;
        .label {
          display: inline-block;
          width: 70px;
        }
        .value {
          color: #828282;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 70px;
    margin: 20px 0;
    display: flex;
    color: #828282;
    .left {
      width: 230px;
      height: 70px;
      box-sizing: border-box;
      padding: 20px;
      margin-right: 20px;
      border-radius: 8px;
      background: #2d3034;
      display: flex;
      align-items: center;
      .nums {
        .item {
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 10px 0;
          .money {
            color: #fff;
            margin-right: 6px;
          }
        }
      }
    }
    .right {
      display: flex;
      flex: 1;
      height: 70px;
      background: #2d3034;
      border-radius: 8px;
      .shouyi {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        .imgs {
          width: 62px;
          height: 48px;
          box-sizing: border-box;
          background: #15181d;
          display: flex;
          align-items: center;
          flex-flow: column;
          padding: 4px;
        }
        .nums {
          .item {
            display: flex;
            align-items: center;
            justify-content: end;
            margin: 10px 0;
            .money {
              color: #fff;
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  .hader {
    line-height: 40px;
    font-size: 16px;
    color: #fff;
  }
  .content {
    width: 100%;
    .table {
      width: 100%;
      .table-column {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        padding: 0 40px;
        background: #192a2c;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .column {
          display: flex;
          align-items: center;
          span {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
